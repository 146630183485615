import React from 'react'
import { useLocation } from '@reach/router'
import { Link, graphql } from 'gatsby'

import IndexLayout from '@/layouts/index'
import { Icon } from '@/components/atoms'
import { GoTop, SEO } from '@/components/molecules'
import { useHandleResponsible } from '@/hooks'

import * as styles from './success.module.scss'

type Props = {
  data: {
    thumbnail: any
  }
}

const ContactSuccessPage: React.FC<Props> = ({ data }) => {
  const thumbnailPath = data.thumbnail.childImageSharp.resize.src
  const location = useLocation()

  const other = {
    title: 'お問い合わせ完了',
    description: 'お問い合わせ完了について',
    url: location.href
  }
  const { goTopSize } = useHandleResponsible({ breakPoint: 1160 })

  return (
    <IndexLayout disableGoTop>
      <SEO type={"website"} thumbnailPath={thumbnailPath} other={other} />
      <div className={styles.root}>
        <div className={styles.title_root}>
          <Icon className={styles.icon} icon={'check'} />
          <h1 className={styles.title}>お問い合わせ完了</h1>
        </div>
        <div className={styles.content}>
          <p>
            この度は、お問い合わせいただき、誠にありがとうございました。<br />
            お送り頂きました内容を確認の上、折返しご連絡させていただきます。<br />
            <br />
            また、ご記入頂きましたメールアドレスへ、自動返信の確認メールを送付しています。<br />
            万が一、自動返信メールが届かない場合は、入力いただいたメールアドレスに間違いがあった可能性がございます。<br /><br />
            メールアドレスをご確認の上、もう一度フォームよりお問い合わせいただきますようにお願い申し上げます。
          </p>
          <div className={styles.top}>
            <Link
              to="/"
              className={styles.top_btn}
              type="submit"
            >
              TOPに戻る
            </Link>
          </div>
        </div>
      </div>
      <GoTop size={goTopSize} bottom={100} />
    </IndexLayout>
  )
}

export default ContactSuccessPage

export const ContactSuccessPageQuery = graphql`
  query {
    thumbnail: file(relativePath: { eq: "blog-ogp-1200x630.png"}) {
      childImageSharp {
        resize(width: 1200, quality: 90) {
          src
        }
      }
    }
  }
`
